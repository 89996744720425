@font-face {
  font-family: 'poppins-regular';
  src: url('./fonts/poppins-regular-webfont.eot');
  src: url('./fonts/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/poppins-regular-webfont.woff2') format('woff2'),
    url('./fonts/poppins-regular-webfont.woff') format('woff'),
    url('./fonts/poppins-regular-webfont.ttf') format('truetype'),
    url('./fonts/poppins-regular-webfont.svg#poppinsregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'poppins-medium';
  src: url('./fonts/poppins-medium-webfont.eot');
  src: url('./fonts/poppins-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/poppins-medium-webfont.woff2') format('woff2'),
    url('./fonts/poppins-medium-webfont.woff') format('woff'),
    url('./fonts/poppins-medium-webfont.ttf') format('truetype'),
    url('./fonts/poppins-medium-webfont.svg#poppinsmedium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'poppins-bold';
  src: url('./fonts/poppins-bold-webfont.eot');
  src: url('./fonts/poppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/poppins-bold-webfont.woff2') format('woff2'), url('./fonts/poppins-bold-webfont.woff') format('woff'),
    url('./fonts/poppins-bold-webfont.ttf') format('truetype'),
    url('./fonts/poppins-bold-webfont.svg#poppinsbold') format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'amphora-regular';
  src: url('./fonts/amphora-regular-webfont.eot');
  src: url('./fonts/amphora-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/amphora-regular-webfont.woff2') format('woff2'),
    url('./fonts/amphora-regular-webfont.woff') format('woff'),
    url('./fonts/amphora-regular-webfont.ttf') format('truetype'),
    url('./fonts/amphora-regular-webfont.svg#amphoraregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
